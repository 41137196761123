import React from 'react';
import { Text } from 'native-base';

export const Example = () => {
  return (
    <Text italic>
      <Text bold>Taj Mahal</Text> is in Agra.
    </Text>
  );
};
